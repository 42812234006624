.imageUpload__style {
  border: 1px dashed var(--secondary_color);
  width: 100px;
  height: 100px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
}

.imagePreview__Style {
  height: 70px;
  width: 70px;
}
