.userProfileSection__Style {
  text-align: center;
}

.userImage_style {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
}
