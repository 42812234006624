.main__header {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: center;
  flex-wrap: wrap;
}

.navitem__style {
  color: white;
  background-color: #958470;
  padding: 6px 14px;
  margin: 10px 15px;
  text-decoration: none;
  border-radius: 6px;
}

.navitem__style:hover {
  color: white;
  background-color: #372b2b;
}

.navActive__style {
  color: white;
  background-color: #372b2b;
}

@media screen and (max-width: 800px) {
  .navitem__style {
    padding: 6px 10px;
    margin: 10px 5px;
    text-decoration: none;
    border-radius: 6px;
  }
}
