.image__style {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.button__style {
  background-color: var(--secondary_color);
  color: white;
  padding: 6px 20px;
  border-radius: 4px;
  border: none;
  margin-top: 10px;
}

.active_button__style {
  background-color: var(--primary_color);
  color: white;
  padding: 6px 20px;
  border-radius: 4px;
  border: none;
  margin-top: 10px;
}
