.heading__Style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.button__Style {
  border: none;
  color: white;
  background-color: #958470;
  padding: 7px 20px;
  border-radius: 6px;
}
