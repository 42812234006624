.row__Style {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 16px;
  padding: 10px 10px;
  margin: 10px 0px;
}

.row__Style:hover {
  background-color: var(--secondary_color);
  color: white;
  transition: all 0.2s ease-in-out;
}

.active_row__Style {
  background-color: var(--secondary_color);
  color: white;
}
