.password__filed__Style {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input_filed_style {
  border: none;
  width: 100%;
  padding: 0.375 rem 0.75 rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1 px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25 rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input_filed_style:focus {
  outline: none;
}
