.verificationCode_style {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input_filed__style {
  flex: 1;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem 0rem 0rem 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input_filed__style:focus {
  outline: none;
}

.sendCode__button {
  border-radius: 0rem 0.25rem 0.25rem 0rem;
  background-color: var(--primary_color);
  color: white;
  padding: 7px 10px;
  border: none;
}
