.button__Style {
  background-color: var(--secondary_color);
  color: white;
  border: none;
  padding: 7px 20px;
  border-radius: 4px;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 40px;
}
