/* .main__div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
} */

.left__style {
  width: 100%;
  position: relative;
  height: 100%;
}

.left__style > img {
  width: 100%;
  height: 100%;
}

.overlay__style {
  position: absolute;
  background-color: rgba(212, 193, 193, 0.048);
  top: 0;
  width: 100%;
  height: 100%;
}

.content__style {
  position: absolute;
  top: 25%;
  left: 30%;
  color: white;
  /* text-align: center; */
}

.content__style img {
  margin-bottom: 25px;
  width: 130px;
  height: 130px;
}

.or__style {
  text-align: center;
  padding: 10px 0px;
  font-weight: bold;
}

.authButton__Style {
  background-color: white;
  border: 1px solid #958470 !important;
  border-radius: 6px;
  width: 100%;
  margin: 4px 0px !important;
}
.authButton__Style > img {
  margin-right: 10px !important;
}

.registerLink__style {
  color: black;
  font-weight: bold;
  text-decoration: none;
}

.registerLink__style:hover {
  color: black;
}

.link__style {
  color: black;
  text-decoration: none;
}

.link__style:hover {
  color: #958470;
  text-decoration: none;
}

.passwordField__Style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid gray;
  padding: 4px 20px;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.passwordField__Style > input {
  width: 100%;
  border: none;
}
.passwordField__Style > input:focus {
  outline: none;
}

.myPlate__logo__style {
  display: none;
}

.downLinks__style {
  text-decoration: none;
  margin: 1rem;
  padding: 3rem 0px;
  color: gray;
}

.downLinks__style:hover {
  color: gray;
}
.downLinksDiv__style {
  text-align: center !important;
  margin-top: 1.5rem !important;
  margin-bottom: 50px;
}

@media screen and (max-width: 800px) {
  .left__style {
    display: none;
  }
  .myPlate__logo__style {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1rem;
  }
  .downLinksDiv__style {
    text-align: center !important;
    margin-top: 1.5rem !important;
    margin-bottom: 50px !important;
  }
}
