.headerCard__Style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.plateProfileImage__style {
  width: 70px;
  height: 70px;
  /* object-fit: cover; */
  border-radius: 5%;
  margin-right: 20px;
  cursor: pointer;
}

.plateInfo__style {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PlateImage__style {
  height: 170px;
  width: 100%;
  padding: 10px 0px;
  border-radius: 6px;
}

.button__Style {
  border-radius: 5px;
  width: 100%;
  color: white;
  background-color: #958470;
  border: none;
  padding: 7px 20px;
  font-weight: bold;
}

.mobile__style {
  display: none !important;
}

@media screen and (max-width: 800px) {
  .desktop__style {
    display: none !important;
  }

  .mobile__style {
    display: block !important;
  }
  .button__Style {
    border-radius: 5px;
    width: 100%;
    color: white;
    background-color: #958470;
    border: none;
    padding: 7px 20px;
    font-weight: bold;
    margin-bottom: 50px;
  }
}
