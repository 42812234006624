.header__style {
  border-bottom: 3px solid var(--primary_color);
  font-weight: bold;
  font-size: 23px;
  border-width: 2px;
}

.button__Style {
  color: white;
  background-color: var(--secondary_color);
  border-radius: 6px;
  border: none;
  padding: 8px 60px;
  margin: 10px 0px;
  font-weight: bold;
  font-size: 20px;
 
}


.plate_image_style {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 1rem;
}

.quantity_button_style {
  border-radius: 4px;
  border: none;
  background-color: var(--secondary_color);
  color: white;
  padding: 2px 5px;
}
