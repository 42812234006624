/* .main__div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
} */

.left__style {
    width: 100%;
    position: relative;
    height: 100%;
  }
  
  .left__style > img {
    width: 100%;
    height: 100%;
  }
  
  .overlay__style {
    position: absolute;
    background-color: rgba(212, 193, 193, 0.212);
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  .content__style {
    position: absolute;
    top: 25%;
    left: 30%;
    color: white;
  }
  .content__style img {
    margin-bottom: 25px;
    width: 130px;
    height: 130px;
  }
  .or__style {
    text-align: center;
    padding: 10px 0px;
    font-weight: bold;
  }
  
  .authButton__Style {
    background-color: white;
    border: 1px solid #958470 !important;
    border-radius: 6px;
    width: 100%;
    margin: 4px 0px !important;
  }
  .authButton__Style > img {
    margin-right: 10px !important;
  }
  
  .registerLink__style {
    color: black;
    font-weight: bold;
    text-decoration: none;
  }
  
  .registerLink__style:hover {
    color: black;
  }
  
  .button__Style {
    border: 1px solid #958470;
    padding: 4px 16px;
    border-radius: 6px;
    background-color: white;
    margin-right: 10px;
  }
  .buttonActive__Style {
    background-color: #958470;
    padding: 5px 16px;
    border-radius: 6px;
    color: white;
    margin-right: 10px;
    border: none;
    transition: all 0.3s ease-in-out;
  }
  
  .verification_field__style {
    display: flex;
  }
  
  .sendCodeInputField__style {
    flex: 1;
    padding: 5px 10px;
    border-radius: 4px 0px 0px 4px;
    border: 1px solid #ced4da;
  }
  
  .sendCodeInputField__style:focus {
    outline: none;
  }
  
  .sendCodeButton__Style {
    border: none;
    border-radius: 0px 4px 4px 0px;
    padding: 0px 10px;
    background-color: #958470;
    color: white;
  }
  
  .myPlate__logo__style {
    display: none;
  }
  
  @media screen and (max-width: 800px) {
    .left__style {
      display: none;
    }
    .myPlate__logo__style {
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding-top: 1rem;
    }
  }
  