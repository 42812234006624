.background__style {
  background-color: #fdecd95b;
}

.searchBox__style {
  border: 1px solid #958470;
  max-width: 100%;
  border-radius: 6px;
  padding: 5px 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.searchBox__style > input {
  border: none;
  width: 100%;
  background-color: transparent;
}
.searchBox__style > input:focus {
  outline: none;
}
.filters__style {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.button__Style {
  color: white;
  background-color: #958470;
  border: none;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 10px 5px;
}

.button__Style:focus {
  color: #958470;
  background-color: white;
  padding: 3px 8px;
  border: 2px solid #372b2b;
}
