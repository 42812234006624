.profile_img_main_style {
  position: relative;
  text-align: center;
}
.edit_profile_img_style {
  position: absolute;
  top: 75px;
  margin-left: -20px;
}
.editIcon_profile_img_style {
  font-size: 24px;
  color: var(--primary_color);
  cursor: pointer;
}
.profile_image_style {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: relative;
}

.profile_editIcon__Style {
  text-align: right !important;
  font-size: 24px;
  color: var(--secondary_color);
  cursor: pointer;
  margin-bottom: 120px;
}
