.mainNavbar__style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 40px;
  background-color: white;
  box-shadow: 0px 0px 17px 15px rgba(128, 128, 128, 0.123);
}

.logImage__style {
  width: 65px;
  height: 65px;
}

.navItems_Style {
  text-decoration: none;
  padding: 0px 17px;
  color: black;
  font-size: 18px;
}

.navItems_Style:hover {
  color: #958470;
}

.activeNavItems__style {
  font-weight: bold;
  color: #958470;
  transition: all 0.1s ease-in-out;
}

.mobile__appbar {
  display: none;
}

.dropbtn {
  padding: 6px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown__content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 110px;
  box-shadow: 0px 8px 16px 2px rgb(0 0 0 / 20%);
  z-index: 1;
  left: -26px;
  top: 56px;
  text-align: center;
  border-radius: 4px;
}

.dropdown__content a {
  color: black;
  padding: 7px 16px;
  text-decoration: none;
  display: block;
}

.dropdown__content a:hover {
}

.dropdown:hover .dropdown__content {
  display: block;
}

.dropdown:hover .dropbtn {
}

@media screen and (max-width: 800px) {
  .middle__Style {
  }
  .middle__Style {
    display: flex;
    align-items: center;
    justify-content: space-around !important;
    width: 100%;
  }
  .mainNavbar__style {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .navItems_Style > span {
    display: none;
  }
  .navItems_Style {
    font-size: 27px;
  }

  .left__Style {
    display: none;
  }
  .right__Style {
    display: none;
  }
  .mobile__appbar {
    display: block;
  }
}
