.accept__button__style {
  background-color: var(--secondary_color);
  color: white;
  border: none;
  padding: 7px 20px;
  border-radius: 4px;
  margin-right: 1rem;
}
.accept__button__style:hover {
  background-color: var(--primary_color);
  color: white;
  border: none;
  padding: 7px 20px;
  border-radius: 4px;
  transition: all 0.4s ease-in-out;
}
.cancel__button__style {
  color: var(--secondary_color);
  background-color: white;
  border: 1px solid var(--secondary_color);
  padding: 7px 20px;
  border-radius: 4px;
}

.cancel__button__style:hover {
  background-color: var(--primary_color);
  color: white;
  border: none;
  padding: 7px 20px;
  border-radius: 4px;
  transition: all 0.4s ease-in-out;
}
