* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

:root {
  --primary_color: #372b2b;
  --secondary_color: #958470;
}

* {
  font-family: "Poppins", sans-serif !important;
}

.common_button_Style {
  border: none;
  background-color: var(--secondary_color);
  color: white;
  padding: 7px 20px;
  border-radius: 5px;
}

.edit_icon_Style {
  color: orange;
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
}
.delete_icon_Style {
  color: red;
  font-size: 24px;
  cursor: pointer;
}

.common_table_image_style {
  height: 40px;
  width: 100px;
  object-fit: contain;
}
