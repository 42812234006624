.main__header {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: center;
}

.navitem__style {
  color: white;
  background-color: #958470;
  padding: 6px 15px;
  margin: 10px 20px;
  text-decoration: none;
  border-radius: 6px;
  border: none;
}

.navitem__style:hover {
  color: white;
  background-color: #372b2b;
}

.navActive__style {
  color: white;
  background-color: #372b2b;
}
